import React, { useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { collection, doc, setDoc } from "firebase/firestore";
import { primaryDB } from "config/firebase";

const AddPost = () => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [blogCard, setBlogCard] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  const [blogDate, setBlogDate] = useState("");
  const [blogUrl, setBlogUrl] = useState("");
  const [allowComment, setAllowComment] = useState(false);
  const [allowLikes, setAllowLikes] = useState(false);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaTags, setMetaTags] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const config = {
    readonly: false, // Enable editing
    height: 400, // Editor height
    uploader: {
      insertImageAsBase64URI: true, // Use Base64 for images
    },
    cleanHTML: {
      allowStyleAttributes: true, // Ensure style attributes like text-align are allowed
    },
    useSplitMode: false, // Ensure the output includes all styles
  };

  
  const handleAddDoc = async () => {
    try {
      const newDocRef = doc(collection(primaryDB, "blogPosts"));
      await setDoc(newDocRef, {
        blogTitle: blogTitle,
        blogCard:blogCard,
        blogAuthor: blogAuthor,
        blogDate: blogDate,
        blogUrl: blogUrl,
        allowComment: allowComment,
        allowLikes: allowLikes,
        metaTitle: metaTitle,
        metaTags: metaTags,
        metaDescription: metaDescription,
        content: content,
      });
      console.log("data added");
      alert("Data added sucessfully");
      setBlogCard("");
      setContent("");
      setBlogTitle("");
      setBlogDate("");
      setBlogUrl("");
      setAllowComment("");
      setAllowLikes("");
      setMetaTitle("");
      setMetaTags("");
      setMetaDescription("");
      setBlogAuthor("");
    } catch (error) {
      console.error("Error adding document: ", error.message || error);
      alert("Some Error occured");
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex flex-col items-center">
            <label htmlFor="fileInput" className="cursor-pointer">
              <img
                src="/addpost/uploadImage.png"
                alt="uploadimage"
                className="max-w-[200px] md:max-w-[328px] rounded-md shadow-sm"
              />
            </label>
            <input
              type="text"
              id="fileInput"
              value={blogCard}
              onChange={(e)=> setBlogCard(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-full gap-4">
            <div className="flex flex-col">
              <label htmlFor="Blogtitle" className="text-sm font-semibold">
                Blog Title
              </label>
              <input
                type="text"
                id="Blogtitle"
                placeholder="Enter Blog Title"
                value={blogTitle}
                onChange={(e) => setBlogTitle(e.target.value)}
                className="border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col w-full">
                <label htmlFor="BlogAuthor" className="text-sm font-semibold">
                  Blog Author
                </label>
                <input
                  type="text"
                  id="BlogAuthor"
                  placeholder="Enter Author Name"
                  value={blogAuthor}
                  onChange={(e) => setBlogAuthor(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 mt-1"
                />
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="BlogDate" className="text-sm font-semibold">
                  Blog Date
                </label>
                <input
                  type="date"
                  id="BlogDate"
                  value={blogDate}
                  onChange={(e) => setBlogDate(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 mt-1"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="BlogUrl" className="text-sm font-semibold">
                Url
              </label>
              <input
                type="text"
                id="BlogUrl"
                placeholder="Url"
                value={blogUrl}
                onChange={(e) => setBlogUrl(e.target.value)}
                className="border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>
            <div className="flex gap-4">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="AllowComment"
                  checked={allowComment}
                  onChange={(e) => setAllowComment(e.target.checked)}
                  className="w-4 h-4"
                />
                <label htmlFor="AllowComment" className="text-sm">
                  Allow Comment
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="AllowLikes"
                  checked={allowLikes}
                  onChange={(e) => setAllowLikes(e.target.checked)}
                  className="w-4 h-4"
                />
                <label htmlFor="AllowLikes" className="text-sm">
                  Allow Likes
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex flex-col w-full">
              <label htmlFor="metaTitle" className="text-sm font-semibold">
                Meta Title
              </label>
              <input
                type="text"
                id="metaTitle"
                placeholder="Meta Title"
                value={metaTitle}
                onChange={(e) => setMetaTitle(e.target.value)}
                className="border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>
            <div className="flex flex-col w-full">
              <label htmlFor="metaTags" className="text-sm font-semibold">
                Meta Tags
              </label>
              <input
                type="text"
                id="metaTags"
                placeholder="Meta Tags"
                value={metaTags}
                onChange={(e) => setMetaTags(e.target.value)}
                className="border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <label htmlFor="metaDescription" className="text-sm font-semibold">
              Meta Description
            </label>
            <input
              type="text"
              id="metaDescription"
              placeholder="Meta Description"
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              className="border border-gray-300 rounded-md p-2 mt-1"
            />
          </div>
        </div>
        <div className="mt-6">
          <label className="text-sm font-semibold">Content</label>
          <JoditEditor
            ref={editor}
            config={config}
            value={content}
            tabIndex={1}
            onChange={(newContent) => setContent(newContent)}
            className="border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        <button
          onClick={handleAddDoc}
          className="mt-6 bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600"
        >
          Publish Post
        </button>
      </>
    </div>
  );
};

export default AddPost;
