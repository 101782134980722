import avatar1 from './avatars/avatar1.png'
import avatar2 from './avatars/avatar2.png'
import avatar3 from './avatars/avatar3.png'
import avatar4 from './avatars/avatar4.png'
import avatar5 from './avatars/avatar5.png'
import avatar6 from './avatars/avatar6.png'
import avatar7 from './avatars/avatar7.png'
import avatar8 from './avatars/avatar8.png'
import avatar9 from './avatars/avatar9.png'
import avatar10 from './avatars/avatar10.png'
import avatar11 from "./avatars/maleProfileImage.png"
import avatar12 from "./avatars/femaleProfileImage.png"


export const assets = {
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12
    
}