import React,{useState} from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { clientDB } from "config/firebase";
import { collection,getDoc,doc } from "firebase/firestore";
import { useEffect } from "react"; 
import { assets } from "assets/img/assets";
import arrow_icon from "assets/img/profile/dropdown_arrow.png"
import { rowsStateInitializer } from "@mui/x-data-grid/internals";

const CustomerDetails = () => {
  const location=useLocation();
  const [userData,setUserData]=useState();
  const history=useHistory();

  const {id}=location.state;

  const getData=async()=>{
    try{
      const profileDocRef=collection(clientDB,"userProfiles");
      const docRef=doc(profileDocRef,id)
      const dataSnapshot=await getDoc(docRef);
      if(dataSnapshot.exists()){
        setUserData(dataSnapshot.data());
      }else{
        console.log("Data not recieved successfully")
      }
      
    }catch(error){
      console.log(error.message);
    }
    
    
  }

  useEffect(()=>{
    getData();
  },[id]);



  
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-2"><img src={arrow_icon} style={{height:"20px",width:"28px",transform:"rotate(90deg)",marginRight:"15px",cursor:"pointer"}} onClick={()=>{history.goBack()}}/><div className="flex h-[30px] w-[1px] border border-gray-500 mr-[15px]"></div><p className="font-semibold text-[20px]">Customer Details</p></div>
      <div className="flex gap-32">
      <div className="w-6/12 bg-white p-2">
        <div className="flex flex-col items-center ">
          <img
            className="rounded-full"
            src={userData?.profileImage?userData.profileImage:userData?.personal.gender==="Male"?assets.avatar11:userData?.personal.gender==="Female"?assets.avatar12:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsPJ9cm0-r5p50py0yUzvM5ZtEB-xWoJRPRA&s"}
            alt="customer"
            style={{height:"140px",width:"140px"}}
          />
          <h2 className="font-semibold text-[30px]">{userData?userData.personal.fullName:""}</h2>
          <h2>Ratings</h2>
        </div>
        <h2 className="">
          <span className="font-semibold text-[20px]">User Id-</span>
          BIZ215678
        </h2>
        <div className="mt-5 mb-2">
          <h1 className="font-semibold text-[20px]">Challenges</h1>
          <p className="border-2 border-blue-500 mb-2 w-[36px]"></p>
          <div className="flex gap-3">
            {
              userData?userData.goals.businessChallenges.map((item,index)=>{
              return <h2 className="text-blue-500 border-blue-500 border rounded-lg px-3 py-1" index={index}>{item}</h2>
              }):""
            }
          </div>
        </div>
        <div className="mt-2 mb-4">
          <h1 className="font-semibold text-[20px]">Goals</h1>
          <p className="border-2 border-blue-500 mb-2 w-[36px]"></p>
          <div className="flex gap-3">
          {
              userData?userData.goals.goal.map((item,index)=>{
              return <h2 className="text-blue-500 border-blue-500 border rounded-lg px-3 py-1" index={index}>{item}</h2>
              }):""
            }
          </div>
        </div>
        <div className="mt-2 mb-4">
          <h1 className="font-semibold text-[20px]">Use Cases</h1>
          <p className="border-2 border-blue-500 mb-2 w-[36px]"></p>
          <div className="flex gap-3">
          {
              userData?userData.goals.usingPlatform.map((item,index)=>{
              return <h2 className="text-blue-500 border-blue-500 border rounded-lg px-3 py-1" index={index}>{item}</h2>
              }):""
            }
          </div>
        </div>
      </div>
      <div className="w-6/12 flex flex-col gap-5">
        <div className="bg-white p-2">
          <h2 className="font-semibold text-[20px]">Basic Information</h2>
          <div className="flex gap-6 flex-wrap">
            <div>
              <h2 className="font-semibold text-[18px]">Email</h2>
              <h2 className="text-gray-500">{userData?userData.personal.email:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">State</h2>
              <h2 className="text-gray-500">{userData?userData.personal.state:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">Role</h2>
              <h2 className="text-gray-500">{userData?userData.personal.fitOption:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">Phone</h2>
              <h2 className="text-gray-500">{userData?userData.personal.phone:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">County</h2>
              <h2 className="text-gray-500">India</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">Stage</h2>
              <h2 className="text-gray-500">{userData?userData.businessInfo.businessStage:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">Sex</h2>
              <h2 className="text-gray-500">{userData?userData.personal.gender:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">City</h2>
              <h2 className="text-gray-500">{userData?userData.personal.city:""}</h2>
            </div>
          </div>
        </div>
        <div className="bg-white p-2">
          <h2 className="font-semibold text-[20px]">Business Information</h2>
          <div className="flex gap-6 flex-wrap">
            <div>
              <h2 className="font-semibold text-[18px]">Business Name</h2>
              <h2 className="text-gray-500">{userData?userData.businessInfo.businessName:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">Business Industry</h2>
              <h2 className="text-gray-500">{userData?userData.businessInfo.businessIndustry:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">GST</h2>
              <h2 className="text-gray-500">{userData?userData.businessInfo.gstNumber:""}</h2>
            </div>
            <div>
              <h2 className="font-semibold text-[18px]">Business Stage</h2>
              <h2 className="text-gray-500">{userData?userData.businessInfo.businessStage:""}</h2>
            </div>
          </div>
        </div>
        <div className="bg-white p-2">
          <div>
            <div className="mt-2 mb-4">
              <h1 className="font-semibold text-[20px] mb-2">
                Required Services
              </h1>
              <div className="flex gap-3">
                <h2 className="text-blue-500 border-blue-500 border rounded-lg px-3 py-1">
                  Banding
                </h2>
                <h2 className="text-blue-500 border-blue-500 border rounded-lg px-3 py-1">
                  Marketing
                </h2>
                <h2 className="text-blue-500 border-blue-500 border rounded-lg px-3 py-1">
                  seo
                </h2>
                <h2 className="text-blue-500 border-blue-500 border rounded-lg px-3 py-1">
                  gtm
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
