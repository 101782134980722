import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useMemo,useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import Search from "./Search";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { deleteDoc, doc } from "firebase/firestore";
import { primaryDB } from "config/firebase";

import { FiSearch } from "react-icons/fi";
import { assets } from "../../../../assets/img/assets"

export default function ComplexTable(props) {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const toast = useToast();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter, // Add useGlobalFilter hook for global search
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (

    <div className="absolute top-0 left-0 w-full p-4 ">
      {/* Left: Customer Text */}
      <div className="mb-20 flex">
        <span className="absolute top-2 left-4 text-gray-700 font-bold ml-3 mt-5 text-2xl">
          Customers
        </span>

        {/* Center: Search Box */}
        <div className="flex items-center justify-center align-center w-full ">
          <div className="flex items-center space-x-2">
            <FiSearch className="text-gray-500 text-lg" />
            <input
              type="text"
              placeholder="Search..."
              className="bg-transparent border-none focus:outline-none text-gray-700 placeholder-gray-500"
            />
          </div>
        </div>

        {/* Right: Icons and Profile */}
        <div className="flex items-center space-x-4 mr-20">
          {/* Mail Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
          </svg>


          {/* Bell Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
          </svg>
        </div>



        {/* Partner Profile */}

        <div className="flex  items-center space-x-4 mr-10">
          <img className="w-10 h-10 rounded-full" src={assets.avatar1} alt="Partner" />
          <div className="flex flex-col">
            <span className=" font-bold text-gray-700">Admin</span>
            <h1 className="font-bold text-gray-500 ">CRB</h1>
          </div>






        </div>
      </div>
      <Flex px='25px' justify="center" align='center' gap="20px" >
        <Search />
        <div className="ml-14  flex justify-centere  items-center w-38 h-17 px-4 py-2 bg-transparent rounded-md">
          <button className="border-2 border-blue-500 text-blue-500 px-4 py-2 rounded-md">
            + New Partner
          </button>
        </div>




        {/* <Menu /> */}
      </Flex>







      <br />
      {/* Global Search Input */}
      <br />


      <Table {...getTableProps()} variant="simple" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              bg="transparent"
              _hover={{ bg: "transparent" }}
              style={{ backgroundColor: "transparent" }} // Ensure inline style for transparency
            >
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  textAlign="center"
                  bg="transparent"
                  style={{ backgroundColor: "transparent" }} // Ensure inline style for transparency
                >
                  <Flex
                    justify="center"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                    
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                key={index}
                bg="white"
                borderRadius="md"
                boxShadow="lg"
                _hover={{ boxShadow: "xl", transform: "scale(1.01)" }}
                mb="10px"
                
              >
                {row.cells.map((cell, index) => (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize="14px"
                    py="8px"
                    borderColor="transparent"
                    borderRadius="md"
                  >
                    {cell.render("Cell")}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>



      {/* Pagination */}
      <Flex mt="4" justify="center" align="center" style={{ marginInline: '1rem' }}>
        <Flex align="center" mx="3">
          {/* Fixed Page Number Buttons (1 to 8) */}
          {Array.from({ length: 8 }).map((_, index) => {
            const pageNum = index; // page numbers from 0 to 7
            const isPageValid = pageNum < pageOptions.length; // Check if the page exists
            const isCurrentPage = pageIndex === pageNum; // Check if this is the current page
            return (
              <Button
                key={pageNum}
                onClick={() => isPageValid && gotoPage(pageNum)} // Only navigate if page is valid
                isDisabled={!isPageValid}
                mx="1"
                style={{
                  backgroundColor: isCurrentPage ? '#3182ce' : '#fff', // Blue for current page
                  color: isCurrentPage ? '#fff' : '#000', // White text for current page
                  border: '1px solid #ccc',
                  borderRadius: '0.375rem',
                }}
                className="px-4 py-2 hover:bg-gray-200"
              >
                {pageNum + 1}
              </Button>
            );
          })}

          {/* Next Button: "Next >" */}
          <Button
            onClick={() => gotoPage(pageIndex + 1)}
            isDisabled={!canNextPage}
            mx="1"
            className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded-sm"
          >
            {"Next >"}
          </Button>
        </Flex>
      </Flex>




    </div>
  );
}




