import { Box, Button, Flex, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import EnquiryTable from "../assigned/components/EnquiryTable";
import { EnquiryColumn, EnquiryRow } from "./data/AssignedData";
import PartnerTable from "./components/PartnerTable";
import { collection, onSnapshot,getDocs } from "firebase/firestore";
import { partnerDB, primaryDB } from "config/firebase";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const AssignedPartners = () => {
  const [enquiryColumnData, setEnquiryColumnData] = useState([]);
  const [enquiryRowData, setEnquiryRowData] = useState();
  const [partnersData, setPartnersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unsubscribe, setUnSubscribe] = useState(null);
  const location=useLocation();

  useEffect(() => {
    setEnquiryColumnData(EnquiryColumn);
    setEnquiryRowData(location.state?.item)
    console.log(enquiryColumnData);
    console.log("location state",location.state)
  }, []);

  useEffect(()=>{
    console.log("The Enquiry Data recieved from previous page is:",enquiryRowData)
  },[enquiryRowData])

  useEffect(() => {
    getPartners();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);


  const getPartners = async() => {
    try {
      const partnerCollectionRef=collection(partnerDB,"userProfiles");
      const partnerDataSnapshot=await getDocs(partnerCollectionRef);
      if(!partnerDataSnapshot.empty){
        console.log("partnerData:",partnerDataSnapshot.docs[0].data());
        const partnersDataArray=partnerDataSnapshot.docs.map((item)=>item.data())
        console.log("partners array:",partnersDataArray)
        setPartnersData(partnersDataArray);
        setLoading(false);
      }

    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "NAME",
      accessor: "firstName",
    },
    {
      Header: "Low Ticket",
      accessor: "lowticket",
    },
    {
      Header: "Services",
      accessor: "service",
    },
    {
      Header: "Experience",
      accessor: "experience",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Ratings",
      accessor: "ratings",
    },
    {
      Header: "Portfolio",
      accessor: "portfolio",
    },
  ];
  return (
    <div>
      <Box marginTop="1rem">
        <EnquiryTable columnData={enquiryColumnData} rowData={enquiryRowData} />
      </Box>
      <Text mt="1rem" mb="1rem">
        Customer Decision Priority
      </Text>
      <SimpleGrid columns={6} spacing={5}>
        <Button
          w="100%"
          h="2rem"
          mr="0.5rem"
          textAlign="center"
          backgroundColor="#65C756"
          color="#fff"
          borderRadius="50px 0px 0px 50px"
          fontSize={12}
        >
          Cost
        </Button>
        <Button
          w="100%"
          h="2rem"
          mr="0.5rem"
          textAlign="center"
          backgroundColor="#65C756"
          color="#fff"
          borderRadius="0px 0px 0px 0px"
          fontSize={12}
        >
          Experience
        </Button>
        <Button
          w="100%"
          h="2rem"
          mr="0.5rem"
          textAlign="center"
          backgroundColor="#E0E0E0"
          color="#263238"
          borderRadius="0px 0px 0px 0px"
          fontSize={12}
        >
          Ratings
        </Button>
        <Button
          w="100%"
          h="2rem"
          mr="0.5rem"
          textAlign="center"
          backgroundColor="#65C756"
          color="#fff"
          borderRadius="0px 0px 0px 0px"
          fontSize={12}
        >
          Payment Structure
        </Button>
        <Button
          w="100%"
          h="2rem"
          mr="0.5rem"
          textAlign="center"
          backgroundColor="#E0E0E0"
          color="#263238"
          borderRadius="0px 0px 0px 0px"
          fontSize={12}
        >
          Timeline
        </Button>
        <Button
          w="100%"
          h="2rem"
          mr="0.5rem"
          textAlign="center"
          backgroundColor="#E0E0E0"
          color="#263238"
          borderRadius="2px 60px 60px 2px"
          fontSize={12}
        >
          Quality work
        </Button>
      </SimpleGrid>

      <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
        <Flex align="center" justify="center" h="100%">
        {loading ? (
            <Spinner size="xl" marginTop={"5rem"} />
          ) : (
            <PartnerTable columnData={columns} tableData={partnersData} enquiryData={enquiryRowData} />
          )}
        </Flex>
      </Box>
    </div>
  );
};

export default AssignedPartners;
