import * as React from 'react';
import { useState, useEffect } from 'react';
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import ComplexTable from "./components/ComplexTable";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { partnerDB,clientDB } from "config/firebase";
import { assets } from '../../../assets/img/assets';

const PartnerManagement = () => {

    const [partnersData, setPartnersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [unsubscribe, setUnSubscribe] = useState(null)

    useEffect(() => {
        getPartners();
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    console.log("Partners Data :", partnersData);
    const getPartners = () => {
        try {
            const subscribe = onSnapshot(collection(clientDB, "userProfiles"), (snapshot) => {
                const users = snapshot.docs.map((doc, index) => ({
                    ...doc.data(),
                    id: doc.id,
                    srNo: index + 1,
                }));
                setPartnersData(users);
                setLoading(false);
            });
            setUnSubscribe(() => subscribe);
        } catch (error) {
            console.log(error.message);
            setLoading(false);
        }
    };

    const columns = [
        {
            Header: "PROFILE",
            accessor: "avatar",
            Cell: () => (
                <img
                    src={assets.avatar1} // Static local image
                    alt="Avatar"
                    style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                    }}
                />
            ),
        },
        {
            Header: "NAME",
            accessor: "personal.fullName",
        },
        {
            Header: "Mobile",
            accessor: "personal.phone",
        },
        {
            Header: "EMAIL",
            accessor: "personal.email",
        },
        {
            Header: "LOCATION",
            accessor: "personal.city",
        },
        {
            Header: "PORTFOLIO",
            accessor: "portfolio",
          },
          {
            Header: "SERVICE",
            accessor: "service",
          },
          {
            Header: "EXP",
            accessor: "exp",
          },
          {
            Header: "ROLE",
            accessor: "personal.fitOption",
          },
      
        {
            Header: "ACTIONS",
            accessor: "actions",
            Cell: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "18px" }}>
                    <button
                        style={{
                            all: "unset", // Resets default button styles
                            color: "#007BFF", // Blue text color
                            cursor: "pointer",
                            textDecoration: "underline", // Optional for link-style
                            fontSize: "14px",
                        }}
                        onClick={() => alert("View All clicked!")}
                    >
                        View All
                    </button>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#007BFF"
                        className="size-6"
                        style={{ width: "24px", height: "24px" }}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                    </svg>

                    {/* Vertical grey border between the SVGs */}
                    <div
                        style={{
                            borderLeft: "3px solid #ccc", // Grey color border
                            height: "24px", // Matches the height of the SVGs
                            margin: "0 8px", // Optional: add spacing around the border
                        }}
                    />

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#007BFF"
                        className="size-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                    </svg>
                </div>
            ),
        }
    ]

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <Flex align="center" justify="center" h="100%">
                {loading ? (
                    <Spinner size="xl" marginTop={'5rem'} />
                ) : (
                    <ComplexTable
                        columnsData={columns}
                        tableData={partnersData}
                    />
                )}
            </Flex>
        </Box>
    )
}
export default PartnerManagement