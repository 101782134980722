import { Box, Flex, Spinner,useToast } from "@chakra-ui/react";
import { primaryDB,clientDB } from "config/firebase";
import { collection, getDocs, onSnapshot,deleteDoc, doc } from "firebase/firestore";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {useHistory} from "react-router-dom";
import ComplexTable from "./components/ComplexTable";
import { assets } from "../../../assets/img/assets";

const UserManagement = () => {
  const [usersData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unsubscribe, setUnSubscribe] = useState(null);
  const toast = useToast();
  const history=useHistory();

  useEffect(() => {
    getUsers();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const getUsers = () => {
    try {
      const subscribe = onSnapshot(collection(clientDB, "userProfiles"), (snapshot) => {
        const users = snapshot.docs.map((doc, index) => ({
          ...doc.data(),
          id: doc.id,
          srNo: index + 1,
          name: doc.data().personal.fullName,
        }));
        setUserData(users);
        setLoading(false);
      });
      setUnSubscribe(() => subscribe);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  // const columns = [

  //   {
  //     Header: "NAME",
  //     accessor: rowData => `${rowData.firstName} ${rowData.lastName}`,
  //   },
  //   {
  //     Header: "MOBILE",
  //     accessor: "phoneNumber",
  //   },
  //   {
  //     Header: "EMAIL",
  //     accessor: "email",
  //   },
  //   {
  //     Header: "LOCATION",
  //     accessor: "location",
  //   },
  //   
  //   
  //   {
  //     Header: "EXP",
  //     accessor: "exp",
  //   },


  //   {
  //     Header: "ACTION",
  //     accessor: "action",
  //   },
  // ];
  const columns = [
    {
      accessor: "avatar",
      Cell: ({row}) => (
        <div className="flex h-10 w-10 justify-center items-center rounded-full">
        <img
          src={row.original?.profileImage?row.original.profileImage:row.original.personal.gender==="Male"?assets.avatar11:assets.avatar12} // Static local image
          alt="Avatar"
          style={{
            borderRadius: "100%",
            height:"40px",
            width:"40px"
          }}
        />
        </div>
        
      ),
    },

    {
      Header: "NAME",
      accessor: "personal.fullName",
    },
    {
      Header: "Mobile",
      accessor: "personal.phone",
    },
    {
      Header: "EMAIL",
      accessor: "personal.email",
    },

    {
      Header: "LOCATION",
      accessor: "personal.city",
    },
    {
      Header: "POSITION",
      accessor: "personal.fitOption",
    },
    {
      Header: "STAGE",
      accessor: "businessInfo.businessStage",
    },
    {
      Header: "ACTIONS",
      accessor: "actions",
      Cell: ({ row }) => (
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <button
            style={{
              all: "unset", // Resets default button styles
              color: "#007BFF", // Blue text color
              cursor: "pointer",
              textDecoration: "underline", // Optional for link-style
              fontSize: "14px",
            }}
            onClick={() =>{ 
              history.push("/admin/customerDetails",{id:row.original.id});
            }}
          >
            View All
          </button>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#007BFF"
            className="size-6"
            style={{ width: "24px", height: "24px",cursor:"pointer" }}
            onClick={() => console.log("User Clicked the Edit Button!")} // Pass the current row data to handleDelete
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>

          {/* Vertical grey border between the SVGs */}
          <div
            style={{
              borderLeft: "2px solid #ccc", // Grey color border
              height: "24px", // Matches the height of the SVGs
              margin: "0 8px", // Optional: add spacing around the border
            }}
          />
          <button onClick={() => console.log("User Clicked the Delete Button!")}>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg></button>
        </div>
      ),
    }

  ];

  const handleDelete = async (data) => {
    try {
      // Reference to the document to be deleted
      const userDocRef = doc(primaryDB, "users", data?.original.id);

      // Delete the document
      await deleteDoc(userDocRef);

      // Show success toast
      toast({
        description: "Deleted Successfully",
        status: "success",
        position: 'top',
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting user:", error.message);

      // Optionally show an error toast
      toast({
        description: "Error deleting user.",
        status: "error",
        position: 'top',
        duration: 1000,
        isClosable: true,
      });
    }
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex align="center" justify="center" h="100%">
        {loading ? (
          <Spinner size="xl" marginTop={'5rem'} />
        ) : (
          <ComplexTable
            columnsData={columns}
            tableData={usersData}
          />
        )}
      </Flex>
    </Box>
  )

};
export default UserManagement;
