import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, FormControl, FormLabel, Img, ModalOverlay, Progress, SimpleGrid, Table, TableCaption, TableContainer, Select, CheckboxGroup, Tbody, Td, Text, Tfoot, Th, Thead, Checkbox, Tr } from '@chakra-ui/react';
import React, { useState,useEffect } from 'react';
import CardTimeline from "../assets/img/system-uicons_card-timeline.png";
import { Avatar, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from 'react-icons/fa';
import { SlGraph } from "react-icons/sl";
import { GoBell } from 'react-icons/go';
import { TbTag } from "react-icons/tb";
import { HSeparator } from 'components/separator/Separator';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { collection,getDocs,doc } from 'firebase/firestore';
import {clientDB} from "../../../../config/firebase"

// Example data for serviceDetails

const EnquiryTable = ({ columnData, rowData }) => {
    const [openActionModal, setOpenActionModal] = useState(false); 
    const [selectedService, setSelectedService] = useState('Web Development');
    const [decisionParameters,setDecisionParameters]=useState([]);
    const [formData,setFormData]=useState([]);
    const [modalData,setModalData]=useState({});
    const history=useHistory();
    const serviceDetails = {
        'Web Development': {
            serviceName: 'Web Development',
            timeline: '3 Months',
            cost: '$2000',
            purposeOfWebsite: 'E-commerce',
            numberOfPages: '20',
            featuresRequired: ['SEO Optimization', 'Forms', 'Live Chat']
        },
        'Design': {
            serviceName: 'Design',
            timeline: '2 Months',
            cost: '$1500',
            platform: 'Web',
            designScope: 'Redesign',
            featuresOrPages: 'Homepage, About Us',
            targetAudience: 'General Public'
        },
        'SEO': {
            serviceName: 'SEO',
            timeline: '1 Month',
            cost: '$1000',
            websiteURL: 'www.example.com',
            targetKeywords: 'SEO, Web Development',
            goal: ['Improve Rankings', 'Increase Traffic'],
            competitorWebsites: 'www.competitor1.com',
            existingSEOEfforts: { hasEfforts: true, details: 'Previous SEO work on blogs' }
        },
        'Marketing': {
            serviceName: 'Marketing',
            timeline: '1 Month',
            cost: '$1200',
            emailPlatform: 'Mailchimp',
            targetAudienceMarketing: 'B2B',
            numberOfEmails: '11-50',
            campaignGoals: ['Increase Sales', 'Drive Traffic']
        }
    };

    const handleModal=(item)=>{
        setModalData(item);
    }

    const getData=async()=>{
        try{
            const collectionRef=collection(clientDB,"clientFormData");
            const docSnapshot=await getDocs(collectionRef);
            if(!docSnapshot.empty){
                const newData=docSnapshot.docs.map((item)=>item.data());
                setFormData(newData);
                
            }
        }catch(error){
            console.log(error.message);
        }
    }

    useEffect(()=>{
        getData();
    },[])


    useEffect(()=>{
        console.log("Modal data:",modalData);
        setDecisionParameters(modalData?.serviceDetails?.decisionParameters);
    },[modalData]);

    useEffect(()=>{
        console.log("Decision Parameters:",decisionParameters)
    },[decisionParameters]);

    useEffect(()=>{

    },[openActionModal])




    return (
        <>
            <TableContainer
                border="3px solid"
                borderRadius="0.5rem"
                borderColor="#D3D3D3"
            >
                <Flex m="1rem 2rem" justifyContent="space-between">
                    <Flex
                        alignItems="center"
                        fontWeight="bold"
                        fontSize="1.1rem"
                    >
                        <Text>Enquiries</Text>
                    </Flex>
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Img src={CardTimeline} alt="CardTimeline" style={{ width: "30px", height: "30px", marginRight: "0.6rem" }} />
                        <Text mr="0.6rem">Past 15 days</Text>
                        <ChevronDownIcon mr="0.6rem" />
                        <Text mr="0.6rem" >17 Jan 2024</Text>
                        <Text><span style={{ marginRight: "0.6rem" }}>to</span> 08 Feb 2024</Text>
                    </Flex>
                </Flex>
                <Table
                    variant='simple'
                    overflowX="hidden"
                    style={{ borderCollapse: 'separate', borderSpacing: '0 1rem' }}
                >
                    <Thead height="4rem" mb="1rem">
                        <Tr backgroundColor="#D0E3F3">
                            {columnData?.map((item, index) => (
                                <Th key={index}>{item}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {formData.map((item, index) => (
                            <Tr key={index}
                                backgroundColor="#EBF2FA"
                                boxShadow="0rem 0.25rem 0.25rem 0rem #00000040"
                            >
                                <Td>
                                    <Flex direction="column">
                                        <Text fontWeight="bold">{item?.categoryDetails.category}</Text>
                                        <Text fontSize="sm">{item?.categoryDetails.subCategory}</Text>
                                    </Flex>
                                </Td>
                                <Td>
                                    <Flex
                                        direction="column"
                                    >
                                        <Text fontWeight="bold">{item?.serviceDetails.projectStart}</Text>
                                        <Text fontSize="sm">{item?.time}</Text>
                                    </Flex>
                                </Td>
                                <Td>{item?.personalDetails?.fullName}</Td>
                                <Td fontWeight="bold">₹{item?.projectDetails.priceRange[0]}-{item?.projectDetails.priceRange[1]}</Td>
                                <Td fontWeight="bold">{item?.serviceDetails?.purposeOfWebsite}</Td>
                                <Td fontWeight="bold">{item?.projectDetails?.projectTimeline}</Td>
                                <Td fontWeight="bold">{item?.status}</Td>
                                <Td>
                                    <Button
                                        backgroundColor="#9BC5EF"
                                        borderRadius="2rem"
                                        cursor="pointer"
                                        onClick={() => { 
                                            setOpenActionModal(!openActionModal)
                                            setSelectedService(item?.categoryDetails?.category)
                                            handleModal(item)
                                         }}
                                    >
                                        Action
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Flex justifyContent="center" alignItems="center" marginBottom="1rem">
                    <Button
                        backgroundColor="#D7E9FD"
                        borderRadius="10px"
                        boxShadow="3px 5px 5px 0px #ccc"
                    >
                        View All
                    </Button>
                </Flex>
            </TableContainer>


            {/* Action Modal Opening */}
            <Modal
                size="xl"
                isOpen={openActionModal}
                onClose={() => { setOpenActionModal(!openActionModal) }}
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor="#EBF2FA"
                >
                    <ModalHeader color="#1B2559">{modalData?.categoryDetails?.category}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Details</Text>
                        <Flex pt="1rem" pb="1rem">
                            <Flex w="50%">
                                <Avatar size='sm' />
                                <p style={{marginLeft:"0.5rem"}}>{modalData?.personalDetails?.fullName}</p>
                            </Flex>
                            <Flex w="50%">
                            <p style={{marginLeft:"0.5rem"}}>{modalData?.categoryDetails?.subCategory}</p>
                            </Flex>
                        </Flex>
                        <Flex pt="1rem" pb="1rem">
                            <Flex
                                w="50%"
                                alignItems="center"
                            >
                                <Icon as={SlGraph} />
                                <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.purposeOfWebsite}</p>
                            </Flex>
                            <Flex
                                w="50%"
                                alignItems="center"
                            >
                                <Icon as={FaRegCalendarAlt} />
                                <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.projectStart}</p>
                            </Flex>
                        </Flex>
                        <Text>Pricings</Text>
                        <Flex pt="1rem" pb="1rem">
                            <Flex
                                w="50%"
                                alignItems="center"
                            >
                                <Icon as={BsClock} />
                                <p style={{marginLeft:"0.5rem"}}>{modalData?.projectDetails?.projectTimeline}</p>
                            </Flex>
                            <Flex
                                w="50%"
                                alignItems="center">
                                <Icon as={GoBell} />
                                <p style={{marginLeft:"0.5rem"}}>Immediately</p>
                            </Flex>
                        </Flex>
                        <Flex
                            pt="1rem" pb="1rem"
                            w="50%"
                            alignItems="center"
                        >
                            <Icon as={TbTag} />
                            <p style={{marginLeft:"0.5rem"}}>{modalData?.projectDetails?.priceRange[0]}-{modalData?.projectDetails?.priceRange[1]}</p>
                        </Flex>

                        {/* Service Details - Merged Dynamic Fields */}
                        <div>
                            <Text>Service Details</Text>

                            {/* Service Selection */}
                            {/* <Select onChange={(e) => setSelectedService(e.target.value)} value={selectedService} mb="1rem">
                                <option value="Web Development">Web Development</option>
                                <option value="Design">Design</option>
                                <option value="SEO">SEO</option>
                                <option value="Marketing">Marketing</option>
                            </Select> */}

                            {/* Service Name and Timeline */}
                            <Flex pt="1rem" pb="1rem">
                                <Flex w="50%" alignItems="center">
                                    <Icon as={BsClock} />
                                    <Input ml="0.5rem" variant="flushed" placeholder={serviceDetails[selectedService]?.serviceName || 'Service Name'} />
                                </Flex>
                                <Flex w="50%" alignItems="center">
                                    <Icon as={GoBell} />
                                    <Input ml="0.5rem" variant="flushed" placeholder={serviceDetails[selectedService]?.timeline || 'Timeline'} />
                                </Flex>
                            </Flex>

                            {/* Cost */}
                            <Flex pt="1rem" pb="1rem" w="50%" alignItems="center">
                                <Icon as={TbTag} />
                                <Input ml="0.5rem" variant="flushed" placeholder={serviceDetails[selectedService]?.cost || 'Cost'} />
                            </Flex>

                            {/* Render fields based on selected service */}
                            {selectedService === 'Web Development' && (
                                <div>
                                    <Text>Purpose of Website</Text>
                                    <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.purposeOfWebsite}</p>
                                    <Text>Number of Pages</Text>
                                    <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.numberOfPages}</p>
                                    <Text>Features Required</Text>
                                    <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.featuresRequired}</p>
                                </div>
                            )}

                            {selectedService === 'Design' && (
                                <div>
                                    <Text>Platform</Text>
                                    <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.platform}</p>
                                    <Text>Design Scope</Text>
                                    <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.designScope}</p>
                                    <Text>Features or Pages to Design</Text>
                                    <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.pages}</p>
                                    <Text>Target Audience</Text>
                                    <p style={{marginLeft:"0.5rem"}}>{modalData?.serviceDetails?.targetAudience}</p>
                                </div>
                            )}

                            {selectedService === 'SEO' && (
                                <div>
                                    <Text>Website URL</Text>
                                    <Input variant="flushed" placeholder={serviceDetails['SEO']?.websiteURL || 'www.example.com'} />
                                    <Text>Target Keywords/Topics</Text>
                                    <Input variant="flushed" placeholder={serviceDetails['SEO']?.targetKeywords || 'SEO, Web Development'} />
                                    <Text>Goal</Text>
                                    <CheckboxGroup value={serviceDetails['SEO']?.goal || []}>
                                        <Checkbox value="Improve Rankings">Improve Rankings</Checkbox>
                                        <Checkbox value="Increase Traffic">Increase Traffic</Checkbox>
                                        <Checkbox value="Local SEO">Local SEO</Checkbox>
                                        <Checkbox value="E-commerce SEO">E-commerce SEO</Checkbox>
                                    </CheckboxGroup>
                                    <Text>Competitor Websites (Optional)</Text>
                                    <Input variant="flushed" placeholder={serviceDetails['SEO']?.competitorWebsites || 'www.competitor1.com'} />
                                    <Text>Existing SEO Efforts</Text>
                                    <Input variant="flushed" placeholder={serviceDetails['SEO']?.existingSEOEfforts?.details || 'None'} />
                                </div>
                            )}

                            {selectedService === 'Marketing' && (
                                <div>
                                    <Text>Email Platform Used</Text>
                                    <Input variant="flushed" placeholder={serviceDetails['Marketing']?.emailPlatform || 'Mailchimp'} />
                                    <Text>Target Audience</Text>
                                    <Select variant="flushed" placeholder="Select Target Audience">
                                        <option value="B2B">B2B</option>
                                        <option value="B2C">B2C</option>
                                    </Select>
                                    <Text>Number of Emails</Text>
                                    <Select variant="flushed" placeholder="Select Number of Emails">
                                        <option value="1-10">1-10</option>
                                        <option value="11-50">11-50</option>
                                        <option value="51+">51+</option>
                                    </Select>
                                    <Text>Campaign Goals</Text>
                                    <CheckboxGroup value={serviceDetails['Marketing']?.campaignGoals || []}>
                                        <Checkbox value="Increase Sales">Increase Sales</Checkbox>
                                        <Checkbox value="Announce Product">Announce Product</Checkbox>
                                        <Checkbox value="Drive Traffic">Drive Traffic</Checkbox>
                                    </CheckboxGroup>
                                </div>
                            )}
                        </div>

                        <Text pt="0.8rem" pb="0.8rem">Project Requirement</Text>
                        <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                        <p style={{marginTop:"0.8rem",marginBottom:"0.8rem",fontSize:"sm"}}>{modalData?.projectDetails?.projectDetails}</p>
                        <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                        <Text mt="1rem" mb="1rem">Customer Decision Priority</Text>
                        <SimpleGrid columns={3} spacing={5}>
                            <Button w="100%" h="2rem" mr="0.5rem" textAlign="center" backgroundColor={decisionParameters?.includes("Price")?"#65C756":"#E0E0E0"} color={decisionParameters?.includes("Price")?"#fff":"#263238"} borderRadius="50px 0px 0px 50px">Cost</Button>
                            <Button w="100%" h="2rem" mr="0.5rem" textAlign="center" backgroundColor={decisionParameters?.includes("Experience")?"#65C756":"#E0E0E0"} color={decisionParameters?.includes("Experience")?"#fff":"#263238"} borderRadius="0px 0px 0px 0px">Experience</Button>
                            <Button w="100%" h="2rem" mr="0.5rem" textAlign="center" backgroundColor={decisionParameters?.includes("Quality work")?"#65C756":"#E0E0E0"} color={decisionParameters?.includes("Quality work")?"#fff":"#263238"} borderRadius="2px 60px 60px 2px">Quality work</Button>
                            <Button w="100%" h="2rem" mr="0.5rem" textAlign="center" backgroundColor={decisionParameters?.includes("Timeline")?"#65C756":"#E0E0E0"} color={decisionParameters?.includes("Timeline")?"#fff":"#263238"} borderRadius="50px 0px 0px 50px">Timeline</Button>
                            <Button w="100%" h="2rem" mr="0.5rem" textAlign="center" backgroundColor={decisionParameters?.includes("Cost structure")?"#65C756":"#E0E0E0"} color={decisionParameters?.includes("Cost structure")?"#fff":"#263238"} borderRadius="0px 0px 0px 0px">Payment Structure</Button>
                        </SimpleGrid>
                    </ModalBody>

                    <Text ml="1.5rem">Take Action</Text>
                    <Flex
                        ml="1.5rem"
                        mt="1rem" mb="1rem"
                        justifyContent="flex-start"
                        color="#FFFFFF"
                    >
                        <Button backgroundColor="#407BFF" onClick={()=>history.push('/admin/assigned-partners',{item:modalData})}>Assign Partners</Button>  
                        <Button ml="1rem" backgroundColor="#F28F8F">Reject</Button>
                    </Flex>

                    <FormControl mt="1rem" mb="1rem" w="90%" ml="1.5rem">
                        <FormLabel htmlFor='question'>Remarks</FormLabel>
                        <Input
                            id='question'
                            type='text'
                            placeholder='Type your Question'
                            minHeight="6rem"
                            backgroundColor="#FFFFFF"
                        />
                    </FormControl>

                    
                    <ModalFooter>
                    
                        <Button
                            backgroundColor="#5B8EF1"
                            variant="solid"
                            mr={3}
                            onClick={() => setOpenActionModal(false)}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EnquiryTable;
