import React, { useState } from "react";
import { FaFilter, FaSearch } from "react-icons/fa"; // Install this package with 'npm install react-icons'

const Search = () => {
  const [query, setQuery] = useState("");

  const handleSearch = (e) => {
    setQuery(e.target.value);
    console.log("Search query:", e.target.value); // Replace with your search logic
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <input
          type="text"
          value={query}
          onChange={handleSearch}
          placeholder="Search client..."
          style={styles.input}
        />
        <button style={styles.filterButton} onClick={() => alert("Filter options")}>
          <FaFilter style={styles.filterIcon} /> Filter
        </button>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    backgroundColor: "transparent", // Light grey background
    padding: "15px",
    borderRadius: "10px", // Rounded outer container
    display: "flex",
    justifyContent: "flex-start", // Align to the left
    alignItems: "flex-start", // Align to the top
    width: "100%",
    maxWidth: "700px", // You can adjust this width as needed
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  },
  input: {
    flex: 1,
    padding: "8px 12px", // Smaller padding
    border: "1px solid #ddd",
    borderRadius: "10px", // Rounded input
    fontSize: "14px", // Smaller font size
    transition: "all 0.3s ease",
  },
  searchIcon: {
    position: "absolute",
    left: "12px", // Adjust to create space for the icon
    top: "50%",
    transform: "translateY(-50%)", // Center the icon vertically
    fontSize: "16px", // Icon size
    color: "#888", // Icon color
    marginLeft: "8px", // Add margin to separate the icon from the left edge
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "8px 14px", // Smaller padding
    border: "1px solid #ddd",
    borderRadius: "10px", // Rounded button
    backgroundColor: "#fff",
    fontSize: "14px", // Smaller font size
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  filterIcon: {
    fontSize: "14px", // Slightly larger icon
  },
};

export default Search;
